import * as React from "react";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {Card, Col, Row, Spin} from "antd";
import axiosAdminInstance from "../../other/api";
import {Line} from '@ant-design/plots';

const Dashboard = () => {
    const {user, logout} = useAuth();
    const [loading, setLoading] = useState(false);
    const [dailyReportData, setDailyReportData] = useState<any[]>([]);


    const Installs = ({data}: any) => {
        const config = {
            data,
            xField: 'Date',
            yField: 'Installs',
            point: {
                shapeField: 'square',
                sizeField: 4,
            },
            interaction: {
                tooltip: {
                    marker: false,
                },
            },
            style: {
                lineWidth: 2,
            },
        };
        return <Line {...config} />;
    };

    const Subscriptions = ({data}: any) => {
        const config = {
            data,
            xField: 'Date',
            yField: 'Subscriptions ($)',
            point: {
                shapeField: 'square',
                sizeField: 4,
            },
            interaction: {
                tooltip: {
                    marker: false,
                },
            },
            style: {
                lineWidth: 2,
            },
        };
        return <Line {...config} />;
    };

    const loadStats = async () => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/reports/Daily`);
            setDailyReportData(res.data.reverse());
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadStats();
    }, []);


    return <div><h2>Dashboard</h2>{!loading && <Row gutter={16}>
        <Col span={12}>
            <Card title="Installs" bordered={false}>
                <Installs data={dailyReportData}/>
            </Card>
        </Col>
        <Col span={12}>
            <Card title="Subscriptions $" bordered={false}>
                <Subscriptions data={dailyReportData}/>
            </Card>
        </Col>
    </Row>}
        {loading && <Spin spinning={true}/>}</div>;
}

export {Dashboard};
