import * as React from "react";
import {
    Table,
} from "antd";
import Moment from "react-moment";
import {useEffect, useState} from "react";

const TableReport = ({
                         loading,
                         dataRecords
                     }: any) => {
    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {

        const row = !dataRecords || dataRecords.length == 0 ? {} : dataRecords[0];
        const columnKeys = Object.keys(row).map((key) => {
            return {
                title: key,
                key: () => Math.floor(Math.random() * 1000000).toString(),
                dataIndex: key,
            }
        });
        setColumns(columnKeys);
    }, [dataRecords]);


    return <>

        <Table loading={loading} columns={columns} dataSource={dataRecords}
               scroll={{x: 1500}}
               size="small"/>
    </>;
}

export {TableReport};
