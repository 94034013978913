import * as React from "react";
import type {ColumnsType} from 'antd/es/table';
import {Button, Form, Input, InputNumber, Modal, notification, Select, Space, Spin, Switch, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/authProvider";
import {DeleteOutlined, LockOutlined, TransactionOutlined} from "@ant-design/icons";
import axiosAdminInstance from "../../other/api";
import axios from "axios";
import TextArea from "antd/es/input/TextArea";

const Localization = () => {
    const [loading, setLoading] = useState(false);
    const [translating, setTranslating] = useState(false);
    const [localizations, setLocalizations] = useState<any[]>([]);
    const [filteredLocalizations, setFilteredLocalizations] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [editLocalization, setEditLocalization] = useState<any>(null);
    const [api, contextHolder] = notification.useNotification();
    const [groupOptions, setGroupOptions] = useState<any[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<any>(null);
    const [fromJson, setFromJson] = useState<any>(null);
    const translationKey = '83f6fc394f64463d6097';

    const columns: ColumnsType<any> = [
        {
            title: 'Group',
            dataIndex: 'group',
            key: 'group',
            render: (value) => <Tag color={"volcano"}>{value}</Tag>
        },
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'En',
            dataIndex: 'en',
            key: 'en'
        },
        {
            title: 'Ru',
            dataIndex: 'ru',
            key: 'ru'
        },
        {
            title: 'Ja',
            dataIndex: 'ja',
            key: 'ja'
        },
        {
            title: 'De',
            dataIndex: 'de',
            key: 'de'
        },
        {
            title: 'Fr',
            dataIndex: 'fr',
            key: 'fr'
        },
        {
            title: 'Pt',
            dataIndex: 'pt',
            key: 'pt'
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (value) => <a href={value} target="_blank">Link</a>
        },
        {
            title: 'Action',
            key: 'action',
            fixed: "right",
            render: (record) => (
                <Space size="middle">
                    <a onClick={() => setEditLocalization(record)}>Edit</a>

                    <Button
                        type="primary"
                        icon={<DeleteOutlined/>}
                        onClick={async () => await deleteLocalization(record.key)}
                    >

                    </Button>
                    {/*<a>Delete</a>*/}
                </Space>
            ),
        },
    ];

    const loadLocalizations = async (loadGroups: boolean) => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/localization`);
            setLocalizations(res.data);
            setFilteredLocalizations(res.data);
            if (loadGroups) {
                let options: any[] = [{value: null, label: '-'}];
                // @ts-ignore
                [...new Set(res.data.map(item => item.group))].forEach(item => {
                    options.push({
                        value: item,
                        label: item
                    });
                });
                setGroupOptions(options);
            }


        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const saveLocalization = async () => {
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/localization`, editLocalization);
            setEditLocalization(null);
            await loadLocalizations(false);
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const createFromJson = async () => {
        const items = JSON.parse(fromJson.value);
        try {
            setLoading(true);
            await axiosAdminInstance.put(`/localization/bulk`, items);
            await loadLocalizations(false);
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
            setFromJson(null);
        }
    }

    const deleteLocalization = async (key: string) => {
        try {
            setLoading(true);
            axiosAdminInstance.delete(`/localization/${key}`).then(r => loadLocalizations(false));
            api['success']({
                message: 'Saved'
            });
        } catch (error: any) {
            api['error']({
                message: error.message
            });
        } finally {
            setLoading(false);
        }
    }

    const translateToOtherLanguages = async () => {
        setTranslating(true);
        const record = {...editLocalization};
        if (!record.en)
            return;
        const ruResult: any = await getTranslationText(record.en, "en", "ru");
        if (ruResult.matches && ruResult.matches.length > 0) {
            record.ru = ruResult.matches[0].translation;
        }

        const jaResult: any = await getTranslationText(record.en, "en", "ja");
        if (jaResult.matches && jaResult.matches.length > 0) {
            record.ja = jaResult.matches[0].translation;
        }

        const deResult: any = await getTranslationText(record.en, "en", "de");
        if (deResult.matches && deResult.matches.length > 0) {
            record.de = deResult.matches[0].translation;
        }

        const frResult: any = await getTranslationText(record.en, "en", "fr");
        if (frResult.matches && frResult.matches.length > 0) {
            record.fr = frResult.matches[0].translation;
        }

        const ptResult: any = await getTranslationText(record.en, "en", "pt");
        if (ptResult.matches && ptResult.matches.length > 0) {
            record.pt = ptResult.matches[0].translation;
        }

        setEditLocalization(record);
        setTranslating(false);
    }

    const getTranslationText = async (text: string, fromLang: string, toLang: string) => {
        var result = await axios.get(`https://api.mymemory.translated.net/get?q=${text}&langpair=${fromLang}|${toLang}&key=${translationKey}`);
        return result.data;
    }

    const changeValue = (key: string, value: string) => {
        const record = {...editLocalization};
        record[key] = value;
        setEditLocalization(record);
    }

    const filterData = (key: string, group: string) => {
        const filteredData = localizations.filter(entry =>
            (!key || entry.key.toLowerCase().includes(key.toLowerCase())) && (!group || entry.group == group)
        );
        setFilteredLocalizations(filteredData);
    }

    useEffect(() => {
        loadLocalizations(true);
    }, []);

    return (
        <>
            <h2>Localization <Space>
                <Button
                    onClick={() => {
                        setFromJson({});
                    }}
                >
                    Create from JSON
                </Button>
                <Button onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(localizations));
                    api['success']({
                        message: 'Copied'
                    });
                }}>Copy JSON</Button>
                <Button onClick={() => setEditLocalization({})}>Create</Button></Space></h2>
            {contextHolder}
            {fromJson && <Modal
                title={`Create from JSON`}
                open={!!fromJson}
                okButtonProps={{disabled: loading}}
                cancelButtonProps={{disabled: loading}}
                onOk={createFromJson}
                onCancel={() => setFromJson(null)}
            >
                <div>
                    <span>JSON</span>
                    <TextArea rows={4}
                              placeholder="Json"
                              value={fromJson.value}
                              onChange={e => setFromJson({value: e.target.value})}
                    />
                </div>
            </Modal>}

            <Space>
                <Input
                    placeholder="Key"
                    value={search}
                    onChange={e => {
                        const currValue = e.target.value;
                        setSearch(currValue);
                        filterData(currValue, selectedGroup);
                    }}
                />

                <Select
                    placeholder="Select a group"
                    optionFilterProp="children"
                    value={selectedGroup}
                    style={{minWidth: 150}}
                    onChange={(t) => {
                        setSelectedGroup(t);
                        filterData(search, t);
                    }}
                    // onSearch={onSearch}
                    // filterOption={filterOption}
                    options={groupOptions}
                />
            </Space>

            <Table rowKey="key" loading={loading} pagination={{pageSize: 20}} columns={columns}
                   dataSource={filteredLocalizations}/>

            {editLocalization && <Modal
                title={`Localization record`}
                open={!!editLocalization}
                onOk={saveLocalization}
                onCancel={() => setEditLocalization(null)}
                okButtonProps={{disabled: loading}}
                cancelButtonProps={{disabled: loading}}
            >
                <div>

                    <div>
                        <span>Key</span>
                        <Input
                            placeholder="Key"
                            value={editLocalization.key}
                            onChange={e => changeValue('key', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Group</span>
                        <Input
                            placeholder="Group"
                            value={editLocalization.group}
                            onChange={e => changeValue('group', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>En</span>
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Space.Compact style={{width: '100%'}}>
                                <TextArea rows={5}
                                          placeholder="En"
                                          value={editLocalization.en}
                                          onChange={e => changeValue('en', e.target.value)}
                                />

                                <Button type="primary"
                                        loading={translating}
                                        onClick={translateToOtherLanguages}><TransactionOutlined/></Button>
                            </Space.Compact>
                        </Space>

                    </div>

                    <div>
                        <span>Ru</span>
                        <TextArea rows={5}
                                  placeholder="Ru"
                                  value={editLocalization.ru}
                                  onChange={e => changeValue('ru', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Jp</span>
                        <TextArea rows={5}
                                  placeholder="Ja"
                                  value={editLocalization.ja}
                                  onChange={e => changeValue('ja', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>De</span>
                        <TextArea rows={5}
                                  placeholder="De"
                                  value={editLocalization.de}
                                  onChange={e => changeValue('de', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Fr</span>
                        <TextArea rows={5}
                                  placeholder="Fr"
                                  value={editLocalization.fr}
                                  onChange={e => changeValue('fr', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Pt</span>
                        <TextArea rows={5}
                                  placeholder="Pt"
                                  value={editLocalization.pt}
                                  onChange={e => changeValue('pt', e.target.value)}
                        />
                    </div>

                    <div>
                        <span>Link</span>
                        <TextArea rows={5}
                                  placeholder="link"
                                  value={editLocalization.link}
                                  onChange={e => changeValue('link', e.target.value)}
                        />

                        <iframe width={'100%'} height={500} src={editLocalization.link}/>
                    </div>
                </div>

            </Modal>}
        </>
    );
};

export {Localization};
