import * as React from "react";
import type {ColumnsType} from 'antd/es/table';
import {
    Button,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Space,
    Spin,
    Switch,
    Table,
    Tabs,
    TabsProps,
    Tag
} from "antd";
import {useEffect, useState} from "react";
import axiosAdminInstance from "../../other/api";
import {TableReport} from "./tableReport";

const Reports = () => {
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<any[]>([]);
    const [api, contextHolder] = notification.useNotification();

    const onChange = async (key: string) => {
        await loadReportData(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'Daily',
            label: 'Daily',
            children: <TableReport loading={loading} dataRecords={reportData}/>,
        },
        // {
        //     key: '2',
        //     label: 'Tab 2',
        //     children: 'Content of Tab Pane 2',
        // },
        // {
        //     key: '3',
        //     label: 'Tab 3',
        //     children: 'Content of Tab Pane 3',
        // },
    ];

    const loadReportData = async (reportName: string) => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.get(`/reports/${reportName}`);
            setReportData(res.data);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadReportData(items[0].key);
    }, []);

    return (
        <>
            <h2>Reports</h2>
            <Tabs defaultActiveKey="Daily" items={items} onChange={onChange}/>
        </>
    );
};

export {Reports};
