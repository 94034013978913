import * as React from "react";
import type {ColumnsType} from 'antd/es/table';
import {Button, Form, Input, InputNumber, Modal, notification, Space, Spin, Switch, Table, Tag} from "antd";
import {useEffect, useState} from "react";
import axiosAdminInstance from "../../other/api";

const Users = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItemsCount, setTotalItemsCount] = useState(10);
    const [api, contextHolder] = notification.useNotification();

    const columns: ColumnsType<any> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Ref code',
            dataIndex: 'userRefCode',
            key: 'userRefCode'
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language'
        },
        {
            title: 'Shard',
            dataIndex: 'shardId',
            key: 'shardId'
        },
        {
            title: 'Action',
            key: 'action',
            fixed: "right",
            render: (record) => (
                <Space size="middle">
                    {/*<a>Delete</a>*/}
                </Space>
            ),
        },
    ];

    const loadRecords = async (page: number, pageSize:number) => {
        try {
            setLoading(true);
            const res = await axiosAdminInstance.post(`/users/search`, {page, pageSize, search});
            setTotalItemsCount(res.data.total);
            setUsers(res.data.items);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadRecords(page,pageSize);
    }, []);

    return (
        <>
            <h2>Users</h2>
            <Input
                placeholder="Email"
                value={search}
                onChange={e => {
                    const currValue = e.target.value;
                    setSearch(currValue);
                }}
            />

            <Table rowKey="id" pagination={{
                total: totalItemsCount,
                onChange: (page, pageSize) => {
                    loadRecords(page, pageSize);
                },
            }}
                   loading={loading} columns={columns} dataSource={users}/>
        </>
    );
};

export {Users};
