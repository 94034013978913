import axios from 'axios'
import axiosAuthInstance from "./auth-api";

export const ADMIN_SERVICE_URL = "/api";


//export const ADMIN_SERVICE_URL = "http://localhost:54321";

const axiosAdminInstance = axios.create({
    baseURL: ADMIN_SERVICE_URL
    // other configurations
})
const getUserAccessToken = () => {
    const userData = localStorage.getItem("user");
    if (userData == null)
        return '';
    const user: any = JSON.parse(userData);
    return user ? user.token : '';
}

const getUserRefreshToken = () => {
    const userData = localStorage.getItem("user");
    if (userData == null)
        return '';
    const user: any = JSON.parse(userData);
    return user ? user.refreshToken : '';
}

axiosAdminInstance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${getUserAccessToken()}`;
    return config;
});

axiosAdminInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401) {
            originalRequest._retry = true;
            const accessTokenResult = await axiosAuthInstance.post("/refresh", {refreshToken: getUserRefreshToken()});
            localStorage.setItem("user", JSON.stringify({
                token: accessTokenResult.data.token,
                refreshToken: accessTokenResult.data.refreshToken
            }));
            return axiosAdminInstance(originalRequest);
        }
        return Promise.reject(error)
    },
)
export default axiosAdminInstance;